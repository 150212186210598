import Vue from 'vue';
import {post} from '@/services/api';
import {recoveryPassword} from '../../services/endpoint.json';
import moment from 'moment';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'ForgotPassword',
  mixins: [show_alert_mixin],
  data: () => ({
    email: '',
    canSend: true
  }),
  methods: {

    validateTime() {

      const previous = localStorage.getItem('send');
      if (previous !== undefined && previous !== null) {
        const now = moment().valueOf()
        const old = Number(previous) + Number(5 * 60000)
        return Number(now) >= Number(old);
      } else {
        return true
      }
    },

    async recovery() {
      if (this.validateTime()) {
        const {data} = await post(recoveryPassword, {email: this.email});
        if (data.statusCode === 200) {
          const today = moment().valueOf()
          localStorage.setItem('send', today)
          this.showAlertNotification('An email has been sent to your email address.')
        } else {
          this.showAlertNotification('User inactive or email does not exist', 'error')
        }
      } else {
        this.showAlertNotification(`Please wait 5 minutes before you try again`, 'error')
      }
    },

  },
});
